import React from "react";
import { Button, Col, Layout, Row, Space, theme } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import classes from "./Header.module.scss";
import { Language } from "./components";
import { ReactComponent as User } from "assets/images/icons/user.svg";
import { useLocation } from "react-router-dom";

const Header = () => {
	const { t } = useTranslation();
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { pathname } = useLocation();

	console.log("par", pathname);

	const title: { [key: string]: { title: string; description: string } } = {
		"/": {
			title: t("Статистика"),
			description: t("Дастурнинг имкониятларидан фойдаланганлиг таҳлили")
		},
		"/word-editor": {
			title: t("Сўзларни (Lotin Кирил) таҳрирлаш"),
			description: t("Матнни Кирил алифбосидан Лотин алифбосига автоматик тарзда ўгиринг.")
		},
		"/text-editor": {
			title: t("Онлайн матн редактори"),
			description: t("Матнни яратиш, таҳрирлаш ва сақлаш учун қулай муҳит.")
		},
		"/file-editor": {
			title: t("Онлайн файл матн редактори"),
			description: t("Матнни яратиш, таҳрирлаш ва сақлаш учун қулай муҳит.")
		}
	};

	return (
		<Layout.Header style={{ background: colorBgContainer }} className={classes.header}>
			<Row justify={"space-between"} align={"middle"}>
				<Col>
					<div>
						<h3 className={classes.title}>{title?.[pathname]?.title}</h3>
						{title?.[pathname]?.description && <p className={classes.description}>{title?.[pathname]?.description}</p>}
					</div>
				</Col>
				<Col>
					<Space align={"center"} size={24}>
						<Button type={"primary"} size={"large"} className={"bg-primary-gradient"}>
							{t("Кириш")}
						</Button>
						<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
							<Language />
						</div>
						<div className={cn(classes.profile, classes.notification)}>
							<User />
						</div>
					</Space>
				</Col>
			</Row>
		</Layout.Header>
	);
};

export default Header;
