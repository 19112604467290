import React from "react";
import { Flex, Select } from "antd";
import { useTranslation } from "react-i18next";

import UzFlag from "assets/images/flags/uzb.png";
import RuFlag from "assets/images/flags/ru.png";

const Language = () => {
	const { t } = useTranslation();
	return (
		<Select
			variant="borderless"
			style={{ flex: 1 }}
			defaultValue={"lat"}
			options={[
				{
					label: (
						<Flex align="center" gap={8}>
							<img src={UzFlag} alt="" />
							<span>{t("Ўз")}</span>
						</Flex>
					),
					value: "lat"
				},
				{
					label: (
						<Flex align="center" gap={8}>
							<img src={UzFlag} alt="" />
							<span>{t("O'z")}</span>
						</Flex>
					),
					value: "uz"
				},
				{
					label: (
						<Flex align="center" gap={8}>
							<img src={RuFlag} alt="" />
							<span>{t("Ру")}</span>
						</Flex>
					),
					value: "ru"
				}
			]}
		/>
	);
};

export default Language;
