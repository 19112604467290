import { lazy } from "react";
import { useRoutes } from "react-router-dom";

//pages
const Main = lazy(() => import("pages/Main"));
const WordEditor = lazy(() => import("pages/WordEditor"));
const TextEditor = lazy(() => import("pages/TextEditor"));
const FileEditor = lazy(() => import("pages/FileEditor"));
const DeskEditor = lazy(() => import("pages/DeskEditor"));

export default function RoutesList() {
	return useRoutes([
		{ path: "/", element: <Main /> },
		{ path: "/word-editor", element: <WordEditor /> },
		{ path: "/text-editor", element: <TextEditor /> },
		{ path: "/file-editor", element: <FileEditor /> },
		{ path: "/desk-editor", element: <DeskEditor /> }
	]);
}
