import React, { FC } from "react";
import { ReactComponent as Arrows } from "assets/images/icons/arrowsLeftRight.svg";
import { ReactComponent as Language } from "assets/images/icons/language.svg";
import { ReactComponent as WhiteLang } from "assets/images/icons/whiteLang.svg";
import { ReactComponent as Users } from "assets/images/icons/users.svg";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import { ReactComponent as File } from "assets/images/icons/file.svg";
import { ReactComponent as GlassUsers } from "assets/images/icons/GlassUsers.svg";
import { ReactComponent as Reverse } from "assets/images/icons/reverse.svg";
import { ReactComponent as FileUpload } from "assets/images/icons/file-upload.svg";
import { ReactComponent as Download } from "assets/images/icons/download.svg";
import { ReactComponent as Refresh } from "assets/images/icons/refresh.svg";
import { ReactComponent as FileEdit } from "assets/images/icons/file-edit.svg";
import { ReactComponent as FileDownload } from "assets/images/icons/file-download.svg";

export type IconProps =
	| "arrows"
	| "language"
	| "file"
	| "edit"
	| "whiteLang"
	| "glassUsers"
	| "users"
	| "reverse"
	| "fileUpload"
	| "download"
	| "refresh"
	| "fileEdit"
	| "fileDownload";

type Props = {
	icon: IconProps;
	size?: number;
	color?: string;
	className?: string;
};

export const Icon: FC<Props> = ({ className, icon, size, color }) => {
	const icons = {
		arrows: <Arrows style={{ width: size, height: size, fill: color }} className={className} />,
		users: <Users style={{ width: size, height: size, fill: color }} className={className} />,
		edit: <Edit style={{ width: size, height: size, fill: color }} className={className} />,
		language: <Language style={{ width: size, height: size, fill: color }} className={className} />,
		file: <File style={{ width: size, height: size, fill: color }} className={className} />,
		whiteLang: <WhiteLang style={{ width: size, height: size, fill: color }} className={className} />,
		glassUsers: <GlassUsers style={{ width: size, height: size, fill: color }} className={className} />,
		reverse: <Reverse style={{ width: size, height: size, fill: color }} className={className} />,
		fileUpload: <FileUpload style={{ width: size, height: size, fill: color }} className={className} />,
		download: <Download style={{ width: size, height: size, fill: color }} className={className} />,
		refresh: <Refresh style={{ width: size, height: size, fill: color }} className={className} />,
		fileDownload: <FileDownload style={{ width: size, height: size, fill: color }} className={className} />,
		fileEdit: <FileEdit style={{ width: size, height: size, fill: color }} className={className} />
	};
	return icons[icon];
};
