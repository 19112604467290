import React, { Suspense } from "react";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Layout, LazyLoad } from "components";
import RoutesList from "./routes";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false
		}
	}
});

function App() {
	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<ConfigProvider
					theme={{
						components: {
							Button: {}
						},
						token: {
							colorPrimary: "#364BA8",
							borderRadius: 4
						}
					}}>
					<Layout>
						<Suspense fallback={<LazyLoad />}>
							<RoutesList />
						</Suspense>
					</Layout>
				</ConfigProvider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
