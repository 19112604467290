import React, { useState } from "react";
import { Layout as AntLayout, theme } from "antd";

import classes from "./Layout.module.scss";
import { Header, Sidebar } from "./components";

export type Props = { children?: React.ReactNode };

const { Content } = AntLayout;

const Layout = ({ children }: Props) => {
	const [collapsed] = useState(true);
	const {
		token: { colorBgContainer }
	} = theme.useToken();

	return (
		<AntLayout className={classes.layout}>
			<Sidebar collapsed={collapsed} />

			<AntLayout>
				<Header />
				<Content
					style={{
						background: colorBgContainer
					}}
					className={classes.content}>
					{children}
				</Content>
			</AntLayout>
		</AntLayout>
	);
};

export default Layout;
