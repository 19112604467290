import React from "react";
import { Layout, theme, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { ReactComponent as Logo } from "assets/images/icons/logo.svg";
import { ReactComponent as Home } from "assets/images/icons/home.svg";
import { ReactComponent as Language } from "assets/images/icons/language.svg";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import { ReactComponent as File } from "assets/images/icons/file.svg";
import { ReactComponent as Desk } from "assets/images/icons/desk.svg";
import classes from "./Sidebar.module.scss";

export interface SidebarProps {
	collapsed: boolean;
}

const Sidebar = ({ collapsed }: SidebarProps) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const menu = [
		{
			key: "1",
			icon: <Home />,
			label: t("Статистика"),
			path: "/"
		},
		{
			key: "2",
			icon: <Language />,
			label: t("Сўзларни таҳрирлаш"),
			path: "/word-editor"
		},
		{
			key: "3",
			icon: <Edit />,
			label: t("Онлайн матн редактори"),
			path: "/text-editor"
		},
		{
			key: "4",
			icon: <File />,
			label: t("Онлайн файл матн редактори"),
			path: "/file-editor"
		},
		{
			key: "5",
			icon: <Desk />,
			label: t("Desk"),
			path: "/desk-editor"
		}
	];

	return (
		<Layout.Sider
			width={80}
			trigger={null}
			collapsible
			collapsed={collapsed}
			style={{ background: colorBgContainer }}
			className={classes.sidebar}>
			<Link to={"/"}>
				<div className={classes.logo}>
					<Logo />
				</div>
			</Link>
			<div className={classes.menu}>
				{menu.map((item) => (
					<Link to={item.path} key={item.key}>
						<Tooltip title={item.label} placement={"right"}>
							<div className={cn(classes["menu-item"], pathname === item.path && classes.active)}>{item.icon}</div>
						</Tooltip>
					</Link>
				))}
			</div>
		</Layout.Sider>
	);
};

export default React.memo(Sidebar);
