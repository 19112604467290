import React from "react";
import { Button } from "antd";
import cn from "classnames";

import classes from "./Switch.module.scss";
import { Icon } from "../Icon";

export interface SwitchProps {
	options: [string, string];
	handleSwitch: () => void;
	value?: boolean;
	icon?: React.ReactNode;
}

const Switch = ({ options, handleSwitch, value = false, icon }: SwitchProps) => {
	return (
		<div className={classes.switch}>
			<div className={cn(classes.left, !value && classes.active)}>{options[0]}</div>
			<Button type={"primary"} className={"bg-success-gradient"} onClick={handleSwitch} size={"large"}>
				{icon ? icon : <Icon icon={"reverse"} />}
			</Button>
			<div className={cn(classes.right, value && classes.active)}>{options[1]}</div>
		</div>
	);
};

export default Switch;
